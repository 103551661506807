import React, { useMemo, useState } from 'react';

import ProgressBar from 'app/components/ProgressBar';
import SavingError from 'app/components/SavingError';
import SaveEvidence from 'app/components/steps/SaveEvidence';

import AssetNumberContext from 'app/context/AssetNumberContext';

import useProcess from 'app/hooks/useProcess';
import useStyles from './Process.style';
import ProgressGroup from './ProgressGroup';
import LoadingSkyCell from '../../components/LoadingSkyCell';

const Process = () => {
    const classes = useStyles();

    const [
        assetNumber,
        setAssetNumber,
    ] = useState('');

    const contextValue = useMemo(() => {
        return [
            assetNumber,
            setAssetNumber,
        ];
    }, [
        assetNumber,
        setAssetNumber,
    ]);

    const {
        isLoading,
        isError,
        isSuccess,
        process,
        tab,
        groups,
        handleUpdateStepsInProgressGroup,
        saveProcess,
        onSubmit,
    } = useProcess();

    if (isLoading) {
        return (
            <LoadingSkyCell />
        );
    }

    if (isError) {
        return (
            <SavingError
                onTryAgain={saveProcess}
            />
        );
    }

    if (isSuccess) {
        return <SaveEvidence />;
    }
    const withProgressBar = !['CHECK_TEMPERATURE'].includes(process?.process);

    return (
        <AssetNumberContext.Provider value={contextValue}>
            <form
                className={classes.container}
            >

                {
                    withProgressBar && (
                        <div
                            className={classes.tabProgress}
                        >
                            <ProgressBar
                                items={groups}
                                progressCount={tab}
                            />
                        </div>
                    )
                }

                {
                    groups?.map(({
                        progressGroup,
                        steps,
                    }) => {
                        return tab === progressGroup && (
                            <ProgressGroup
                                tab={tab}
                                process={process?.process}
                                key={progressGroup}
                                progressGroup={progressGroup}
                                handleToNextGroup={onSubmit}
                                handleUpdateStepsInProgressGroup={handleUpdateStepsInProgressGroup}
                                steps={steps}
                            />
                        );
                    })
                }
            </form>
        </AssetNumberContext.Provider>
    );
};

export default Process;
