import { useMutation } from 'react-query';

import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

function useCreateProcessEvidence() {
    const {
        FlexibleRequest: shipmentProcess,
    } = useSecureBackendEndpoints('skyship/process').requests;

    return useMutation<any>((process) => {
        return shipmentProcess('POST', '', process)
            .then((data) => {
                return data.data;
            });
    }, {
        retry: false,
    });
}

export default useCreateProcessEvidence;
