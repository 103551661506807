import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorScreen from 'app/components/ErrorScreen';
import useFetchProcesses from 'app/hooks/useFetchProcesses';

import useUserRoles from 'app/hooks/useUserRoles';
import useStyles from './MainMenu.style';
import LoadingSkyCell from '../../components/LoadingSkyCell';
import { ROLES } from '../../utils/constants';

export const SSI_PROCESS_EXECUTION = 'SSI_PROCESS_EXECUTION';

const MainMenu = () => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const roles = useUserRoles();

    const hasRoles = useMemo(() => {
        return roles.includes(ROLES.SKYSHIP_EVIDENCE_COLLECTION)
            || roles.includes(ROLES.SKYSHIP_TEMPERATURE_READOUT);
    }, [roles]);

    const {
        processes,
        isProcessesLoading,
    } = useFetchProcesses(hasRoles);

    if (isProcessesLoading || !processes) {
        return (
            <LoadingSkyCell />
        );
    }

    return (
        <>
            {
                hasRoles && (
                    <div
                        className={classes.container}
                    >
                        <h1
                            className={classes.title}
                        >
                            {t('I_WANT_TO')}
                        </h1>

                        <div
                            className={classes.controls}
                        >
                            {
                                processes?.map(({
                                    processLabel,
                                    processName,
                                    process,
                                }) => {
                                    return (
                                        <NavLink
                                            key={processName}
                                            className={classes.control}
                                            to={process}
                                        >
                                            {t(processLabel).toUpperCase()}
                                        </NavLink>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                !hasRoles && roles.length !== 0 && <ErrorScreen />
            }
        </>
    );
};

export default MainMenu;
