import React, { useCallback, useMemo, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import useEnumerateDevices from 'app/hooks/useEnumerateDevices';
import AcceptButton from 'app/components/AcceptButton';
import SkipButton from 'app/components/SkipButton';
import isMediaDevicesAvailable from 'app/utils/isMediaDevicesAvailable';

import { useTranslation } from 'react-i18next';
import useStyles from './PhotoPickerRaw.style';
import { Constraints } from '../../utils/dataTypes';
import PhotoPickerFileInput from '../PhotoPicker/PhotoPickerFileInput';
import FieldTitle from '../FieldTitle';
import styles from '../steps/PictureMultiple/PictureMultiple.module.scss';

type Props = {
    value?: string,
    setValue?: (...arg: any) => any,
    label?: string,
    onAccept: (...arg: any) => any,
    onSkip: (...arg: any) => any,
    title: string,
    subTitle: string
}

const CAMERA_WIDTH = 320;
const CAMERA_HEIGHT = 320;

const PhotoPickerRaw = ({
    label = '',
    value = '',
    onAccept,
    onSkip,
    title,
    subTitle,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const input = useRef(null);
    const device = useEnumerateDevices();

    const [imageSrc, setImageSrc] = useState(null);

    const getPhotoFromInput = useCallback((event) => {
        setImageSrc(window.URL.createObjectURL(event.target.files[0]));
    }, [
        onAccept,
        label,
    ]);

    const handleCancel = useCallback(() => {
        setImageSrc(null);
    }, [
        onAccept,
        label,
    ]);

    const captureImage = useCallback(() => {
        const image: Constraints = input.current.getScreenshot({
            width: 720,
            height: 720,
            aspectRatio: 1,
        });

        setImageSrc(image);
    }, [
        input,
        label,
        onAccept,
    ]);

    const videoConstraints = useMemo(() => {
        const constraints: Constraints = {
            audio: false,
            height: 720,
            width: 720,
            video: {
                aspectRatio: 1,
            },
        };

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode;
        }

        return constraints;
    }, [device]);

    if (!isMediaDevicesAvailable() && device) {
        return (
            <PhotoPickerFileInput
                value={value}
                onChange={getPhotoFromInput}
            />
        );
    }

    return (
        <>
            <FieldTitle
                title={imageSrc ? t('REVIEW_PICTURE') : title}
                subTitle={subTitle}
            />

            <div
                className={styles.tabContent}
            >

                {
                    !imageSrc && (
                        <>
                            <Webcam
                                audio={false}
                                height={CAMERA_HEIGHT}
                                width={CAMERA_WIDTH}
                                className={classes.capturer}
                                ref={input}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                screenshotQuality={1}
                            />

                            <div
                                className={classes.cameraControls}
                            >
                                <button // eslint-disable-line jsx-a11y/control-has-associated-label
                                    type="button"
                                    className={classes.captureButton}
                                    onClick={captureImage}
                                />

                                {
                                    onSkip && (
                                        <SkipButton
                                            onClick={onSkip}
                                        />
                                    )
                                }
                            </div>
                        </>
                    )
                }
                {
                    imageSrc && (
                        <>

                            <div className={classes.imageContainer}>
                                <img
                                    className={classes.image}
                                    src={imageSrc}
                                    alt={label}
                                />
                            </div>

                            <div
                                className={classes.cameraControls}
                            >
                                <AcceptButton
                                    onCancel={handleCancel}
                                    onSubmit={() => onAccept(imageSrc)}
                                />
                            </div>

                        </>
                    )

                }

            </div>

        </>
    );
};

export default PhotoPickerRaw;
