import React, { useState } from 'react';
import {
    useTranslation,
} from 'react-i18next';

import FieldTitle from 'app/components/FieldTitle';
import BarcodeDecodedMessage from 'app/components/BarcodeReader/BarcodeDecodedMessage';
import { useHistory } from 'react-router-dom';

import { Input } from '@material-ui/core';
import styles from './PalletNumber.module.scss';
import MultiReader from '../../MultiReader';
import Button from '../../Button';

type Props = {
    title?: string,
    value?: {
        palletCode: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const PalletNumber = ({
    value = null,
    title = 'PALLET_CODE',
    subTitle = '',
    label,
    skippable = false,
    onChange,
    onSubmit,
}: Props) => {
    const {
        t,
    } = useTranslation();

    const history = useHistory();
    const [manualInput, setManualInput] = useState('');
    const [showManualInput, setShowManualInput] = useState(false);

    if (value?.palletCode) {
        return (
            <BarcodeDecodedMessage
                value={value?.palletCode}
                onSubmit={onSubmit}
                identifiedMessage="PALLET_IDENTIFIED"
                onRepeat={
                    () => {
                        onChange({
                            target: {
                                value: '',
                            },
                        }, label);
                        setManualInput('');
                    }
                }
            />
        );
    }

    return (
        <>
            <FieldTitle
                title={t(showManualInput ? 'ENTER_PALLET_CODE' : title)}
                subTitle={subTitle}
            />

            <div
                className={[
                    styles.tabContent,
                    showManualInput ? styles.fullHeight : '',
                ].join(' ')}
            >
                {
                    !showManualInput && (
                        <>

                            <MultiReader
                                label={label}
                                skippable={skippable}
                                rawInput
                                onScan={(value) => {
                                    onChange({
                                        target: {
                                            value,
                                        },
                                    }, label);
                                }}
                                onSkip={onSubmit}
                            />

                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('ENTER_MANUALLY')}
                                    onClick={() => {
                                        setShowManualInput(true);
                                    }}
                                />
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                />
                            </div>
                        </>
                    )
                }

                {
                    showManualInput && (
                        <div className={styles.manualInput}>
                            <Input
                                onKeyPress={(event => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setShowManualInput(false);
                                        onChange({
                                            target: {
                                                value: manualInput,
                                            },
                                        }, label);
                                    }
                                })}
                                className={styles.input}
                                placeholder={t('ENTER_CODE')}
                                value={manualInput}
                                onChange={e => setManualInput(e.target.value)}
                            />
                            <div className={styles.divider} />
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        setManualInput('');
                                    }}
                                />
                                <Button
                                    text={t('CONFIRM')}
                                    disabled={!manualInput}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        onChange({
                                            target: {
                                                value: manualInput,
                                            },
                                        }, label);
                                    }}
                                />
                            </div>

                        </div>
                    )
                }
            </div>
        </>
    );
};

export default PalletNumber;
