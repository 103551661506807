import React from 'react';

import styles from './PhotoPickerFileInput.module.scss';

type Props = {
    label?: string,
    value?: string,
    onChange: (...arg: any) => any,
}

const PhotoPickerFileInput = ({
    label = '',
    value = '',
    onChange,
}: Props) => {
    return (
        <>
            <input
                type="file"
                onChange={onChange}
            />

            {
                value && (
                    <img
                        className={styles.image}
                        src={value}
                        alt={label}
                    />
                )
            }
        </>
    );
};

export default PhotoPickerFileInput;
