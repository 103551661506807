import SerialNumber from 'app/components/steps/SerialNumber';
import SerialNumberComparison from 'app/components/steps/SerialNumberComparison';
import PalletNumber from 'app/components/steps/PalletNumber';
import PalletNumberComparison from 'app/components/steps/PalletNumberComparison';
import Confirmation from 'app/components/steps/Confirmation';
import Picture from 'app/components/steps/Picture';
import PictureComparison from 'app/components/steps/PictureComparison';
import TemperatureWelcomeScreen from './TemperatureWelcomeScreen';
import TemperatureInfo from './TemperatureInfo';
import RawScan from './RawScan';
import ChoiceMultiple from './ChoiceMultiple';
import ChoiceSingle from './ChoiceSingle';
import YesNo from './YesNo';
import Text from './Text';
import TextValidation from './TextValidation';
import PictureMultiple from './PictureMultiple';

const stepsMapper = {
    CONTAINER_SERIAL_NUMBER: {
        Component: SerialNumber,
    },
    CONTAINER_SERIAL_NUMBER_COMPARISON: {
        Component: SerialNumberComparison,
    },
    PICTURE: {
        Component: Picture,
    },
    PICTURE_MULTIPLE: {
        Component: PictureMultiple,
    },
    PICTURE_COMPARISON: {
        Component: PictureComparison,
    },
    PALLET_CODE: {
        Component: PalletNumber,
    },
    PALLET_CODE_COMPARISON: {
        Component: PalletNumberComparison,
    },
    CONFIRMATION: {
        Component: Confirmation,
    },
    WELCOME_SCREEN: {
        Component: TemperatureWelcomeScreen,
    },
    CONTAINER_SERIAL_NUMBER_RAW: {
        Component: RawScan,
    },
    CHECK_TEMPERATURE: {
        Component: TemperatureInfo,
    },
    CHOICE_MULTIPLE: {
        Component: ChoiceMultiple,
    },
    CHOICE_SINGLE: {
        Component: ChoiceSingle,
    },
    YES_NO: {
        Component: YesNo,
    },
    TEXT: {
        Component: Text,
    },
    TEXT_VALIDATED: {
        Component: TextValidation,
    },
};

export default stepsMapper;
