import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            color: '#000000DE',

        },
        content: {
            display: 'flex',
        },
        title: {
            font: '36px Roboto, sans-serif',
            fontWeight: 200,
            wordBreak: 'break-word',
            textAlign: 'center',
        },
        checkIcon: {
            color: theme.palette.primary[200],
            height: '70px !important',
            width: '70px !important',
        },
        choice: {
            margin: '7px 0',
        },
        choices: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
        },
        checkboxLabel: {
            color: '#747474',
            font: 'normal normal normal 24px/29px Roboto',
            paddingLeft: 5,
        },
        checkbox: {
            color: theme.palette.secondary[400],
            padding: 0,
            '&$checked': {
                color: 'red',
            },
            '& svg': {
                fontSize: 36,
            },

        },
    };
});

export default useStyles;
