import { ExtendedProfile, UserInfo } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null,
    id: null,
    companyName: null,
    name: '',
    email: '',
};

export const getAvatar = (gender: string) => {
    return gender || '';
};

export const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        name,
    } = extendedProfile;
    const { companyId: compIdArr = [] } = attributes;
    const [companyId = null] = compIdArr;

    return {
        avatar,
        assignedRoles,
        companyId,
        email,
        name,
    };
};
