import React from 'react';
import { Classes } from 'jss';
import { ICONS } from 'app/utils/constants';
import getGlobalAssetUrl from 'app/utils/getGlobalAssetUrl';

type Props = {
    classes: Classes,
    onZoomIn: Function,
    onZoomOut: Function,
    onLeft: Function,
    onRight: Function,
    onReset: Function,
    showPan: boolean,
}

const MobileZoomBar = ({
    onLeft,
    onReset,
    onZoomIn,
    onZoomOut,
    onRight,
    classes,
    showPan,
}: Props) => {
    return (
        <div className={classes.mobileToolbar}>
            {
                showPan && (
                    <button
                        type="button"
                        className={classes.toolButton}
                        onClick={(event) => {
                            event.preventDefault();
                            onLeft();
                        }}
                    >
                        <img src={getGlobalAssetUrl(ICONS.panLeft)} alt="" />
                    </button>
                )
            }
            {
                showPan && (
                    <button
                        type="button"
                        className={classes.toolButton}
                        onClick={(event) => {
                            event.preventDefault();
                            onRight();
                        }}
                    >
                        <img src={getGlobalAssetUrl(ICONS.panRight)} alt="zoomReset" />

                    </button>
                )
            }
            <div className={classes.divider} />
            <button
                type="button"
                className={classes.toolButton}
                onClick={(event) => {
                    event.preventDefault();
                    onReset();
                }}
            >
                <img src={getGlobalAssetUrl(ICONS.zoomReset)} alt="zoomReset" />
            </button>
            <button
                type="button"
                className={classes.toolButton}
                onClick={(event) => {
                    event.preventDefault();
                    onZoomOut();
                }}
            >
                <img src={getGlobalAssetUrl(ICONS.zoomOut)} alt="zoomOut" />
            </button>
            <button
                type="button"
                className={classes.toolButton}
                onClick={(event) => {
                    event.preventDefault();
                    onZoomIn();
                }}
            >
                <img src={getGlobalAssetUrl(ICONS.zoomIn)} alt="zoomIn" />
            </button>

        </div>
    );
};

export default MobileZoomBar;
