import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';

ReactDOM.render(
    <Suspense fallback="Loading">
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>

        </React.StrictMode>
    </Suspense>,
    document.getElementById('root'),
);

serviceWorkerRegistration.register();
