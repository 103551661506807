import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Order } from 'app/utils/dataTypes';
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { dateTimeMaskTimestamp, dateTimeMaskUTC } from 'app/utils/constants';
import dateToISO from 'app/utils/dateToISO';
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage';
import useUserRoles from 'app/hooks/useUserRoles';
import TemperatureInfoContents from './TemperatureInfoContents';
import useStyles from './TemperatureInfo.style';
import Button from '../../Button';
import useSecureBackendEndpoints from '../../../hooks/useSecureBackendEndpoints';

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    onReturn: (...arg: any) => any
}
const Buttons = {
    NOW: 'TEMPERATURE.NOW',
    '24h': 'TEMPERATURE.24h',
    '7d': 'TEMPERATURE.7d',
    '14d': 'TEMPERATURE.14d',
    SHIPMENT: 'TEMPERATURE.SHIPMENT',
};

const dateRanges = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
};

const TemperatureInfo = ({
    onReturn,
    value,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [buttonPressed, setButtonPressed] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [currentOrder, setCurrentOrder] = useState<Order>();
    const [sensorDataLoading, setSensorDataLoading] = useState(false);
    const roles = useUserRoles();
    const [orderNumber, setOrderNumber] = useState(null);
    const serialNumber = value?.serialNumber;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);
    const [containerError, setContainerError] = useState(false);
    const {
        FlexibleRequest: skyshipRequest,
    } = useSecureBackendEndpoints('skyship').requests;
    const {
        FlexibleRequest: secureRequest,
    } = useSecureBackendEndpoints('').requests;

    useEffect(() => {
        if (orderNumber && orderNumber.length >= 5) {
            (async () => {
                setLoading(true);
                const result = await secureRequest('GET', `shipment/${orderNumber}`);

                if (result?.data) setCurrentOrder(result?.data || null);
                setLoading(false);
            }
            )();
        }
    }, [orderNumber]);

    useEffect(() => {
        if (serialNumber) {
            (
                async () => {
                    try {
                        const res = await skyshipRequest(
                            'GET',
                            `latest-shipment-of-packaging?serialNumber=${serialNumber}&process=CHECK_TEMPERATURE`,
                        );

                        setOrderNumber(res?.data?.orderNumber);
                        setLoading(false);
                    } catch (e) {
                        if (e?.response?.data?.[0]?.message) {
                            setErrorMessage(`${e?.response?.data?.[0]?.message.toUpperCase()}`);
                        }
                        setLoading(false);
                    }
                }
            )();
        }
    }, [serialNumber]);

    const expectedLease = useMemo(() => {
        return (!currentOrder?.skyMindInfo.leaseEnd && !currentOrder?.skyMindInfo.shipmentEnd) ? moment(
            Math.max(
                moment(currentOrder?.billing?.baseLeaseUntilTimestamp, dateTimeMaskTimestamp)
                    .utc(false)
                    .valueOf(),
                moment()
                    .add({
                        hour: new Date().getTimezoneOffset() / 60,
                        minute: new Date().getTimezoneOffset() % 60,
                    })
                    .valueOf(),
            ),
        ).utc(true) : null;
    }, [currentOrder]);

    const [fromDate, toDate] = useMemo(() => {
        let from;

        let
            to;

        if (buttonPressed === 'SHIPMENT') {
            const { leaseStart, leaseEnd, shipmentStart, shipmentEnd } = currentOrder?.skyMindInfo;

            const start = (shipmentStart && moment(shipmentStart, dateTimeMaskUTC))
                || (leaseStart && moment(leaseStart, dateTimeMaskUTC));
            const end = (shipmentEnd && moment(shipmentEnd, dateTimeMaskUTC).utc(false))
                || (leaseEnd && moment(leaseEnd, dateTimeMaskUTC).utc(false))
                || expectedLease;

            [from, to] = [
                start.utc(false),
                end,
            ];
        } else {
            [from, to] = [
                dateToISO(moment().utc(false).subtract({ day: dateRanges[buttonPressed] })),
                dateToISO(moment().utc(false)),
            ];
        }
        return [from, to];
    }, [
        currentOrder,
        buttonPressed,
        expectedLease,
    ]);

    const currentButtons = useMemo<any[]>(() => {
        const buttonsArray = [
            'NOW',
            '24h',
            '7d',
            '14d',
        ];

        if (currentOrder?.skyMindInfo?.leaseStart) {
            buttonsArray.push('SHIPMENT');
        }
        setButtonPressed('NOW');

        return buttonsArray;
    }, [currentOrder, roles]);

    if (errorMessage || containerError) {
        return (
            <ContainerValidationErrorMessage
                serialNumber={serialNumber}
                error={{
                    message: t(errorMessage),
                    errorCode: 1,
                }}
                cancel={false}
                onRepeat={() => {
                    history.push('/');
                    setImmediate(() => {
                        history.push('/CHECK_TEMPERATURE');
                    });
                }}
            />
        );
    }
    if (loading) {
        return (
            <>
                <div style={{ height: '100%' }} />
                <LoadingSkyCell />
            </>
        );
    }
    return (
        <div className={classes.container}>
            {
                !loading && (
                    <TemperatureInfoContents
                        serialNumber={serialNumber}
                        order={currentOrder}
                        buttonPressed={buttonPressed}
                        setSensorDataLoading={setSensorDataLoading}
                        sensorDataLoading={sensorDataLoading}
                        fromDate={fromDate}
                        toDate={toDate}
                        expectedLease={expectedLease}
                        setContainerError={setContainerError}
                    />
                )
            }

            <div className={classes.buttonsContainer}>

                <div className={classes.buttonsRow}>
                    {
                        currentButtons.length > 1 && currentButtons.map(button => {
                            return (
                                <Button
                                    key={`button_in_row_${button}`}
                                    upperCase
                                    classNames={classes.button}
                                    text={t(Buttons[button])}
                                    loading={sensorDataLoading}
                                    onClick={() => setButtonPressed(button)}
                                    ghost={buttonPressed !== button}
                                />
                            );
                        })
                    }
                </div>

                <div className={classes.buttonFullWidth}>
                    <Button
                        text={t('SCAN_OTHER_CONTAINER')}
                        onClick={onReturn}
                    />
                </div>
            </div>

        </div>
    );
};

export default TemperatureInfo;
