import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import { useUserLocations } from '@skycell-ag/shared-components';

import FieldTitle from 'app/components/FieldTitle';
import BarcodeDecodedMessage from 'app/components/BarcodeReader/BarcodeDecodedMessage';
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage';
import AssetNumberContext from 'app/context/AssetNumberContext';
import useOrderFromSelectedAsset from 'app/hooks/useOrderFromSelectedAsset';
import { Input } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './SerialNumberComparison.module.scss';
import LoadingSkyCell from '../../LoadingSkyCell';
import MultiReader from '../../MultiReader';
import Button from '../../Button';

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...args: any) => any,
    onSubmit: (...args: any) => any,
}

const SerialNumberComparison = ({
    process,
    title = 'SCAN_CONTAINER_CODE',
    subTitle = '',
    skippable = false,
    value = null,
    label,
    onChange,
    onSubmit,
}: Props) => {
    const {
        t,
    } = useTranslation();

    const [manualInput, setManualInput] = useState('');
    const [showManualInput, setShowManualInput] = useState(false);

    const [
        assetNumber,
        setAssetNumber,
    ] = useContext(AssetNumberContext);

    const {
        data: locations,
        isLoading: isLocationsLoading,
        error: locationsError,
    } = useUserLocations({
        refetchOnMount: false,
    });

    const history = useHistory();

    const {
        data,
        error,
        isLoading,
    }: any = useOrderFromSelectedAsset({
        assetNumber,
        process,
        assetType: 'CONTAINER',
        locationId: locations?.[0]?.id,
    });

    useEffect(() => {
        if (!assetNumber && value?.serialNumber) {
            setAssetNumber(value?.serialNumber);
        }
    }, [
        value,
        assetNumber,
        setAssetNumber,
    ]);

    if (isLoading || isLocationsLoading) {
        return <LoadingSkyCell />;
    }

    if (locationsError || error || data?.length === 0) {
        return (
            <ContainerValidationErrorMessage
                serialNumber={assetNumber}
                error={error.response?.data[0]}
                onRepeat={() => {
                    setManualInput('');
                    setAssetNumber(null);
                    onChange({
                        target: {
                            value: '',
                        },
                    }, label);
                }}
            />
        );
    }

    if (value?.serialNumber && data?.length !== 0) {
        return (
            <BarcodeDecodedMessage
                value={value?.serialNumber}
                onSubmit={onSubmit}
                identifiedMessage="CONTAINER_IDENTIFIED"
                onRepeat={() => {
                    setAssetNumber(null);
                    onChange({
                        target: {
                            value: '',
                        },
                    }, label);
                }}

            />
        );
    }

    return (
        <>
            <FieldTitle
                title={t(showManualInput ? 'ENTER_CONTAINER_CODE_TITLE' : title)}
                subTitle={subTitle}
            />

            <div
                className={[
                    styles.tabContent,
                    showManualInput ? styles.fullHeight : '',
                ].join(' ')}
            >

                {
                    !showManualInput && (
                        <>
                            <MultiReader
                                label={label}
                                skippable={skippable}
                                onScan={(value) => {
                                    onChange({
                                        target: {
                                            value,
                                        },
                                    }, label);
                                }}
                                onSkip={onSubmit}
                            />
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('ENTER_MANUALLY')}
                                    onClick={() => {
                                        setShowManualInput(true);
                                    }}
                                />
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                />
                            </div>
                        </>
                    )
                }

                {
                    showManualInput && (
                        <div className={styles.manualInput}>
                            <Input
                                onKeyPress={(event => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setShowManualInput(false);
                                        onChange({
                                            target: {
                                                value: manualInput,
                                            },
                                        }, label);
                                    }
                                })}
                                className={styles.input}
                                placeholder={t('ENTER_CODE')}
                                value={manualInput}
                                onChange={e => setManualInput(e.target.value)}
                            />
                            <div className={styles.divider} />
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        setManualInput('');
                                    }}
                                />
                                <Button
                                    text={t('CONFIRM')}
                                    disabled={!manualInput}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        onChange({
                                            target: {
                                                value: manualInput,
                                            },
                                        }, label);
                                    }}
                                />
                            </div>

                        </div>
                    )
                }
            </div>

        </>
    );
};

export default SerialNumberComparison;
