import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { Cargo, Container } from 'app/utils/dataTypes';
import useStyles from '../TemperatureInfo.style';
import RangeCheckResult from './RangeCheckResult';

type Props = {
    serialNumber: string,
    shipmentNumber: string,
    selectedAsset: Cargo,
    container: Container,
    fromDate: moment.Moment,
    toDate: moment.Moment,
    fromTooltip: string,
    toTooltip: string,
}

const ShipmentCard = ({
    serialNumber = '',
    shipmentNumber = '',
    selectedAsset,
    fromDate,
    toDate,
    fromTooltip,
    toTooltip,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [tooltipState, setTooltipState] = useState({
        from: false,
        to: false,
    });

    useEffect(() => {
        const handler = () => {
            setTooltipState({ from: false, to: false });
        };

        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const handleTooltipClick = (ev: React.MouseEvent<HTMLSpanElement>, type: "from" | "to") => {
        ev.stopPropagation();
        setTooltipState(() => ({
            from: type === 'from',
            to: type === 'to',
        }));
    };

    const minMax = useCallback((min, max) => {
        const minTemp = `${min || '-'}°C`;
        const maxTemp = `${max || '-'}°C`;

        return (
            <div className={classes.minMaxRange}>
                <span>{`${t('MIN')}: ${minTemp}`}</span>
                <span>{`${t('MAX')}: ${maxTemp}`}</span>
            </div>
        );
    }, []);

    return (
        <>
            <div className={classes.shimpentCardHolder}>
                <Card>
                    <div className={classes.shipmentCardContainer}>
                        <div className={classes.shipmentCardTitle}>
                            <span>
                                <b>
                                    {t('CONTAINER')}
                                    :
                                </b>
                                {serialNumber}
                            </span>
                            <span>
                                <b>
                                    {t('TEMPERATURE.SHIPMENT')}
                                    :
                                </b>
                                {shipmentNumber}
                            </span>
                        </div>
                        <div className={classes.shipmentCardBody}>
                            <>
                                <span>{t('TEMPERATURE.ALLOWED_RANGE')}</span>
                                <span>
                                    {minMax(selectedAsset?.skyMindProductRelease?.temperatureRangeMin,
                                        selectedAsset?.skyMindProductRelease?.temperatureRangeMax)}
                                </span>
                            </>
                            <>
                                <span>{t('TEMPERATURE.ACTUAL_RANGE')}</span>
                                <span>
                                    {minMax(selectedAsset?.skyMindProductRelease?.temperatureMin,
                                        selectedAsset?.skyMindProductRelease?.temperatureMax)}
                                </span>
                            </>

                            <RangeCheckResult
                                temperatureStatus={selectedAsset?.skyMindProductRelease?.temperatureStatus}
                                classes={classes}
                            />

                            <>
                                <span>{t('TEMPERATURE.PERIOD')}</span>
                                <span>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -6],
                                                    },
                                                },
                                            ],
                                        }}
                                        onClose={() => setTooltipState(prev => ({ ...prev, from: false }))}
                                        open={tooltipState.from}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        arrow
                                        title={fromTooltip}
                                    >
                                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                        <span
                                            className={classes.spanTooltip}
                                            onClick={
                                                (event) => handleTooltipClick(event, 'from')
                                            }
                                        >
                                            {(fromDate && fromDate.format('DD.MM.YYYY')) || ''}
                                        </span>
                                    </Tooltip>
                                    <span>{` ${t('TO')} `}</span>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -6],
                                                    },
                                                },
                                            ],
                                        }}
                                        onClose={() => setTooltipState(prev => ({ ...prev, to: false }))}
                                        open={tooltipState.to}
                                        arrow
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={toTooltip}
                                    >
                                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                        <span
                                            className={classes.spanTooltip}
                                            onClick={(event) => handleTooltipClick(event, 'to')}
                                        >
                                            {(toDate && toDate.format('DD.MM.YYYY')) || ''}
                                        </span>
                                    </Tooltip>
                                </span>
                            </>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ShipmentCard;
