import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppMenuItem, AppMenuItemType } from '../../utils/dataTypes';
import { useStyles } from './AppDrawerMenuItem.style';
import getGlobalAssetUrl from '../../utils/getGlobalAssetUrl';

type Props = AppMenuItem & { onClose: Function }

const AppDrawerMenuItem = ({
    label = null,
    type = null,
    children = null,
    icon = null,
    route = null,
    onClick = null,
    onClose = null,
}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();

    const redirect = useMemo(() => (route) => {
        if (onClick) {
            onClick();
            return;
        }
        history.push('/');
        setImmediate(() => {
            history.push(route);
        });

        if (onClose) onClose();
    }, []);

    const active = useMemo<boolean>(() => {
        if (pathname === '/') {
            return route === '/';
        }
        return route?.startsWith(pathname);
    }, [pathname]);

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
                className={[
                    classes.item,
                    type === AppMenuItemType.PARENT ? classes.menuHeader : classes.menuItem,
                    active ? classes.active : '',
                ].join(' ')}
                onClick={() => (type === AppMenuItemType.ROUTE ? redirect(route) : {})}
            >
                {icon && (
                    <img
                        className={classes.icon}
                        src={getGlobalAssetUrl(icon)}
                        alt={icon}
                    />
                )}
                <span>{label}</span>
            </div>
            {
                children && children.map(child => (
                    <AppDrawerMenuItem
                        key={`${child.label}_${child.type}`}
                        {...child} onClose={onClose}
                    />
                ))
            }
        </>
    );
};

export default AppDrawerMenuItem;
