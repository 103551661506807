import React, { useMemo } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import useStyles from './MultiReader.style';
import useEnumerateDevices from '../../hooks/useEnumerateDevices';
import getContainerNumberFromGRAI from '../../utils/getContainerNumberFromGRAI';
import SkipButton from '../SkipButton';

type Props = {
    onScan: any,
    onSkip: any,
    label?: string,
    skippable?: boolean,
    rawInput?: boolean,
}
const CAMERA_WIDTH = 320;
const CAMERA_HEIGHT = 320;

const MultiReader = ({
    onScan = () => {
    },
    skippable = false,
    rawInput = false,
    onSkip,
}: Props) => {
    const classes = useStyles();
    const device = useEnumerateDevices();

    const videoConstraints = useMemo(() => {
        const constraints: any = {
            height: 1280,
            width: 720,
            aspectRatio: 1,
        };

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode;
        }

        return constraints;
    }, [device]);

    const handleCodeChange = (err, result) => {
        if (err) {
            return;
        }

        const { text } = result;

        if (!text || text.length === 0) {
            return;
        }

        const processedCode = rawInput ? text : getContainerNumberFromGRAI(text);
        const code = text.length >= 10 ? processedCode : text;

        onScan(code);
    };

    return (
        <>
            <div className={classes.wrapper}>
                <BarcodeScannerComponent
                    videoConstraints={videoConstraints}
                    width={CAMERA_WIDTH}
                    height={CAMERA_HEIGHT}
                    onUpdate={handleCodeChange}
                />

            </div>
            {
                skippable && (
                    <SkipButton
                        onClick={onSkip}
                    />
                )
            }

        </>
    );
};

export default MultiReader;
