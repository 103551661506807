import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl';

import useStyles from './ErrorScreen.style';
import Button from '../Button';
import { AuthContext } from '../../context/AuthContext';

type Props = {
    title?: string
}
const ErrorScreen = ({
    title = 'NO_ACCESS_RIGHTS',
}: Props) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const {
        logout,
    } = useContext(AuthContext);
    const NoAccessRightsImage = getAssetBlobUrl('skyship/images/error_skycenter_sorry.svg');

    return (
        <div
            className={classes.container}
        >
            <img
                className={classes.img}
                src={NoAccessRightsImage}
                alt="error_skycenter_sorry"
            />
            <span>
                {t(title)}
            </span>
            {
                (title === 'NO_ACCESS_RIGHTS' || title === 'ERROR_FORBIDDEN')
                && (
                    <Button
                        text={t('LOGOUT')}
                        onClick={logout}
                    />
                )
            }
        </div>
    );
};

export default ErrorScreen;
