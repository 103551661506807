import axios, { Method } from 'axios';
import { useMemo } from 'react';
import useJWTToken from '../useJWTToken';

import { defaultGetAllQuery, getConfig, getQueries, getStatusStateSetter, Query } from './lib';

const {
    REACT_APP_SECURE_API: apiUrl,
} = process.env;

/**
 * hook to use the Secure Backend.
 * Basic CRUD methods, FlexibleRequest for more specific requests
 * @param objectName name of required object in Secure BE (shipment, user, company etc.)
 * @returns 2 groups of methods: without (requests) and  with status notification (statusNotificationRequests)
 */
const useSecureBackendEndpoints = (objectName: string) => {
    const token = useJWTToken();
    const setStatusState = () => {}; // Whenever we have it
    const statusStateSetter = getStatusStateSetter(setStatusState);
    const instance = axios.create({
        baseURL: `${apiUrl}/${objectName.toLowerCase()}`,
    });
    const config = useMemo(() => getConfig(token, {}), [token]);

    const requests = {
        Get: (id: string | number, queryObj: object = null) => {
            return instance.get(`${id}${getQueries(queryObj)}`, config);
        },
        GetAll: (queryObj: Query = defaultGetAllQuery) => {
            return instance.get(getQueries(queryObj), config);
        },
        Create: (params: object = {}, queryObj: object = null) => {
            return instance.post(getQueries(queryObj), params, config);
        },
        Update: (id: string | number, params: object = {}, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);

            return instance.put(`/${id}${getQueries(queryObj)}`, params, customConfig);
        },
        PutBody: (data: any, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);
            const isNumeric = typeof data === 'number';

            return instance.put(`/${getQueries(queryObj)}`, isNumeric ? data?.toString() : data, customConfig);
        },
        Delete: (id: string | number, queryObj: object = null) => {
            return instance.delete(`/${id}${getQueries(queryObj)}`, config);
        },
        Patch: (id: string | number, data, queryObj: object = null) => {
            return instance.patch(`/${id}${getQueries(queryObj)}`, data, config);
        },
        FlexibleRequest: (type: Method, url: string = '', data = null, headers = {}) => {
            return instance({
                method: type,
                url,
                data,
                headers: {
                    ...config.headers,
                    ...headers,
                },
            });
        },
    };

    const statusNotificationRequests = {
        Get: async (id: string | number, queryObj: object = null) => {
            let time: number = null;

            try {
                time = statusStateSetter.progress(`Request to download ${objectName}...`);
                const response = await instance.get(`/${id}${getQueries(queryObj)}`, config);
                const message = `${objectName} with id ${id} successfully downloaded`;

                statusStateSetter.success(message, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
        GetAll: async (queryObj: Query = defaultGetAllQuery) => {
            let time: number = null;

            try {
                time = statusStateSetter.progress(`Request to download all records of ${objectName}...`);
                const response = await instance.get(getQueries(queryObj), config);
                const message = `${objectName}: All records successfully downloaded`;

                statusStateSetter.success(message, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
        Create: async (params: object = {}, queryObj: object = null) => {
            let time: number = null;

            try {
                time = statusStateSetter.progress(`We're saving your updates in ${objectName}. This might take a bit.`);
                const response = await instance.post(getQueries(queryObj), params, config);
                const message = `We have saved your updates in ${objectName}.`;

                statusStateSetter.success(message, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
        Update: async (id: string | number, params: any = {}, queryObj: object = null, headers = {}) => {
            let time: number = null;
            const customConfig = getConfig(token, headers);

            try {
                time = statusStateSetter.progress(`We're saving your updates in ${objectName}. This might take a bit.`);

                const response = await instance.put(
                    `${id ? `/${id}` : ''}${getQueries(queryObj)}`, params, customConfig,
                );
                const message = `We have saved your updates in ${objectName}.`;

                statusStateSetter.success(message, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
        Delete: async (id: string | number, queryObj: object = null) => {
            let time: number = null;

            try {
                time = statusStateSetter.progress(`We're saving your updates in ${objectName}. This might take a bit.`);

                const response = await instance.delete(`/${id}${getQueries(queryObj)}`, config);

                statusStateSetter.success(`${objectName} with id ${id} successfully deleted`, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
        FlexibleRequest: async (type: Method, url: string, data = null) => {
            let time: number = null;

            try {
                time = statusStateSetter.progress(`Request for ${objectName}. This might take a bit.`);

                const response = await instance({
                    method: type,
                    url,
                    data,
                    ...config,
                });

                statusStateSetter.success(`Success request for ${objectName}`, response.status, [time]);
                return response;
            } catch (error) {
                statusStateSetter.error(error.response, [time]);
                return null;
            }
        },
    };

    return {
        requests,
        statusNotificationRequests,
    };
};

export default useSecureBackendEndpoints;
