import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './TemperatureWelcomeScreen.style';
import getAssetBlobUrl from '../../../utils/getGlobalAssetUrl/getGlobalAssetUrl';
import Button from '../../Button';

type Props = {
    title?: string,
    value?: {
        palletCode: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const TemperatureWelcomeScreen = (
    {
        onSubmit,
    }: Props,
) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <div className={classes.textContainer}>
                    <div className={classes.welcome}>
                        {t('WELCOME')}
                    </div>
                    <div className={classes.text}>{t('SCAN_CHECK_TEMPERATURE')}</div>
                </div>
                <div className={classes.imageWrapper}>
                    <img
                        className={classes.image}
                        src={getAssetBlobUrl('skyship/images/container_intro_compressed.png')}
                        alt="Container"
                    />
                </div>
                <div className={classes.buttonHolder}>
                    <Button
                        text={t('LETS_GO')}
                        onClick={() => onSubmit()}
                    />
                </div>
            </div>
        </>
    );
};

export default TemperatureWelcomeScreen;
