import { AxiosResponse } from 'axios';

export type Query = { [key: string]: string | number }

export const defaultGetAllQuery = { page: 0, pageSize: 1000 };

export const getConfig = (token: string, addedHeaders:object = {}) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
            ...addedHeaders,
        },
    });
};

export const getQueries = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((queryArrObj, key) => {
        return [
            ...queryArrObj,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ];
    }, []);

    return `?${queryArr.join('&')}`;
};

export const getStatusStateSetter = (setStatusState: any) => {
    return {
        success: (message: string, code: number = null, relatedStatuses: number[] = []): void => {
            setStatusState({
                code,
                color: 'success',
                message,
                relatedStatuses,
            });
        },
        error: (response: AxiosResponse, relatedStatuses: number[] = []): void => {
            setStatusState({
                code: response?.status || null,
                color: 'error',
                message: response?.data?.message || 'Some error happend',
                relatedStatuses,
            });
        },
        progress: (message: string, relatedStatuses: number[] = []): number => {
            const time = Date.now();

            setStatusState({
                color: 'info',
                message,
                time,
                relatedStatuses,
            });

            return time;
        },
    };
};
