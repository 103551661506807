import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nLocaleProvider } from '@skycell-ag/shared-components';
import { initVariables } from '@skycell-ag/shared-components/dist/init';

import Root from 'app/Root';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import StylesProvider from './StylesProvider';

import './i18n.ts';
import './App.scss';
import HeaderNavigationProvider from './HeaderNavigationProvider';
import InstallPrompt from './InstallPrompt';
import Auth from './context/AuthContext';

initVariables({
    REACT_APP_SKYMIND_API: process.env.REACT_APP_SKYMIND_API,
});

const queryClient = new QueryClient();

const App = () => {
    const [installEvent, setInstallEvent] = useState<Event>(null);
    const { i18n } = useTranslation();

    const history = useHistory();

    useEffect(() => {
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            const url:string = error?.toJSON()?.config?.url || '';

            if (error?.response?.status === 403 && !url.includes('contact/self') && !url.includes('token')) {
                history.push('/no-access-rights');
            }
            return Promise.reject(error);
        });
    }, []);

    useEffect(() => {
        i18n.changeLanguage('en');
        const installEvent = (ev) => {
            ev.preventDefault();
            setInstallEvent(ev);
        };

        window.addEventListener('beforeinstallprompt', installEvent);

        return () => window.removeEventListener('beforeinstallprompt', installEvent);
    }, []);
    return (
        <Auth>
            <StylesProvider>
                <QueryClientProvider client={queryClient}>
                    <I18nLocaleProvider>
                        <HeaderNavigationProvider>
                            <Root />
                        </HeaderNavigationProvider>

                    </I18nLocaleProvider>
                </QueryClientProvider>
                {
                    installEvent && <InstallPrompt installEvent={installEvent} />
                }
            </StylesProvider>
        </Auth>
    );
};

export default App;
