import { useContext } from 'react';
import useProcesses from '../useProcesses';
import { Process } from '../../utils/dataTypes';
import { accessibleStaticProcesses } from '../useProcesses/staticProcesses';
import useUserRoles from '../useUserRoles';
import { AuthContext } from '../../context/AuthContext';
import { ROLES } from '../../utils/constants';

type FetchData = {
    data: Process[],
    isLoading: boolean,
    error: any,
}

const ROLE_MAP = {
    RECEIVING: [ROLES.SKYSHIP_EVIDENCE_COLLECTION],
    SENDING: [ROLES.SKYSHIP_EVIDENCE_COLLECTION],
};
const filterProcesses = (processes: Process[], roles: string[]) => {
    return processes.filter(process => {
        const processRoles: string[] = ROLE_MAP[process.process];

        return processRoles.map(role => roles
            .includes(role))
            .every(it => it);
    });
};

function useFetchProcesses(hasRoles?) {
    const { userInfo } = useContext(AuthContext);

    const roles = useUserRoles();

    const {
        data: processes,
        isLoading: isProcessesLoading,
        error: processesError,
    }: FetchData | null = useProcesses(userInfo?.companyId, {
        refetchOnMount: false,
        cacheTime: 5,
        enabled: Boolean(userInfo?.companyId && hasRoles),
    });

    if (!isProcessesLoading && processesError) {
        global.console.error('Failed to fetch required processes');
    }

    return {
        isProcessesLoading,
        processesError,
        processes: [
            ...(filterProcesses(processes || [], roles)),
            ...accessibleStaticProcesses(roles || [])],
    };
}

export default useFetchProcesses;
