import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        tabProgress: {
            width: '100%',
            padding: '15px 10px 15px 10px',
            boxSizing: 'border-box',
        },
    };
});

export default useStyles;
