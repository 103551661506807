import React, {
    useCallback, useEffect,
} from 'react';

import stepsMapper from 'app/components/steps/stepsMapper';

import useStepsWithUserInput from '../useStepsWithUserInput';
import useTabs from '../../../hooks/useTabs';
import { PALLET_STEPS } from '../../../utils/constants';

type Props = {
    process: string,
    progressGroup: number,
    // eslint-disable-next-line react/forbid-prop-types
    steps: any,
    handleToNextGroup: Function,
    handleUpdateStepsInProgressGroup: Function,
    tab?: any // no actual use in the code
}

const ProgressGroup = ({
    process,
    progressGroup,
    steps,
    handleToNextGroup,
    handleUpdateStepsInProgressGroup,
}: Props) => {
    const [
        tab,
        handleToNextTab,
        handleToPrevTab,
    ] = useTabs();

    const {
        steps: stepsWithInput,
        addUserInputToSteps,
    } = useStepsWithUserInput(steps);

    const onSubmit: any = useCallback(() => {
        if (steps.length - 1 === tab) {
            handleUpdateStepsInProgressGroup(progressGroup, stepsWithInput);
            return handleToNextGroup();
        }

        return handleToNextTab();
    }, [
        tab,
        steps.length,
        handleToNextTab,
        handleToPrevTab,
        handleToNextGroup,
        stepsWithInput,
        progressGroup,
        handleUpdateStepsInProgressGroup,
    ]);
    const onReturn: any = useCallback(() => {
        return handleToPrevTab();
    }, [
        tab,
        steps.length,
        handleToNextTab,
        handleToPrevTab,
        handleToNextGroup,
        stepsWithInput,
        progressGroup,
        handleUpdateStepsInProgressGroup,
    ]);

    useEffect(() => {
        const palletScan = stepsWithInput.find((it) => PALLET_STEPS.includes(it.stepName));
        const palletTabIndex = stepsWithInput.indexOf(palletScan);

        if (palletScan && tab > palletTabIndex && !palletScan.userInput) {
            const palletStepName = palletScan.stepName;

            if (stepsWithInput[tab]?.stepName.startsWith(palletStepName)) {
                onSubmit();
            }
        }
    }, [stepsWithInput, tab, onSubmit]);

    return (
        <>
            {
                steps?.map(({
                    stepName,
                    stepType,
                    stepTitle,
                    stepSubtitle,
                    isRequired,
                    choices,
                    helpText,
                    validationRegEx,
                    validationErrorMessage,
                }, index) => {
                    const {
                        Component,
                    } = stepsMapper[stepType];

                    const value = stepsWithInput.find((step) => {
                        return step.stepType === stepType && step.stepName === stepName;
                    })?.userInput;

                    return index === tab && (
                        <Component
                            key={stepName}
                            type={stepType}
                            process={process}
                            title={stepTitle}
                            subTitle={stepSubtitle}
                            label={stepName}
                            value={value}
                            skippable={!isRequired}
                            onChange={addUserInputToSteps}
                            onSubmit={onSubmit}
                            choices={choices}
                            helpText={helpText}
                            onReturn={onReturn}
                            validationRegEx={validationRegEx}
                            validationErrorMessage={validationErrorMessage}
                        />
                    );
                })
            }
        </>
    );
};

export default ProgressGroup;
