import { useQuery } from 'react-query';

import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

function useProcesses(companyId, options = {}) {
    const {
        FlexibleRequest: getProcessesRequest,
    } = useSecureBackendEndpoints(`skyship/available-processes?company=${companyId}`).requests;

    const getProcesses = async () => {
        const result = await getProcessesRequest('GET');

        return result?.data || [];
    };

    return useQuery({
        queryKey: [
            'getProcesses',
        ],
        queryFn: getProcesses,
        enabled: Boolean(companyId),
        refetchOnWindowFocus: false,
        retry: false,
        ...options,
    });
}

export default useProcesses;
