import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25,
            height: '100%',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: '#000000DE',

        },
        content: {
            display: 'flex',
        },
        title: {
            font: '36px Roboto, sans-serif',
            fontWeight: 200,
            wordBreak: 'break-word',
            textAlign: 'center',

        },
        inputWrapper: {
            width: '100%',
            height: '35%',
        },
        helpText: {
            color: '#8BD1E8',
            font: 'normal normal normal 16px/19px Roboto;',
            display: 'flex',
        },
        textArea: {
            width: '100%',
            height: '100%',
            resize: 'none',
            border: '1px solid #8BD1E8',
            color: '#747474',
            padding: '10px 20px',
            boxSizing: 'border-box',
            minHeight: 150,
            font: 'normal normal normal 24px/29px Roboto',
            '&:focus': {
                outline: 'none',
            },
        },
        buttonBox: {},
    };
});

export default useStyles;
