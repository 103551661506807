import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
            padding: 50,
            [theme.breakpoints.down('xs')]: {
                padding: 10,
            },

        },

        message: {
            padding: '0 30px 0 30px',
            display: 'inline-flex',
            alignItems: 'center',
        },

        messageTitle: {
            font: 'normal normal 300 36px/43px Roboto',
            fontWeight: 200,
        },

        value: {
            margin: 20,
            padding: '20px 0 20px 0',
            fontFamily: 'Roboto Light',
            fontWeight: 200,
            wordBreak: 'break-word',
            textAlign: 'center',
            fontSize: 56,
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                padding: '0 0 0 0',
                margin: 0,
            },

        },
        icon: {
            color: theme.palette.primary[200],
            height: '100px !important',
            width: '100px !important',
        },

        controls: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
    };
});

export default useStyles;
