import {
    useContext,
} from 'react';
import AssetNumberContext from 'app/context/AssetNumberContext';

const useAssetNumber = () => {
    return useContext(AssetNumberContext);
};

export default useAssetNumber;
