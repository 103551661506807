import moment from 'moment';
import { dateTimeISODate, dateTimeMaskTimestamp } from './constants';

type SensorDataDetails = {
    sensorData?: any[],
    leaseStartTimestamp?: string,
    leaseEndTimestamp?: string,
    baseLeaseUntil?: string,
    shipmentEnd?: string,
    from?: string,
    to?: string,
}
export const trimSensorData = ({
    sensorData,
    leaseStartTimestamp,
    leaseEndTimestamp,
    shipmentEnd,
    from,
    to,
    baseLeaseUntil,
}: SensorDataDetails) => {
    if (!sensorData || sensorData.length === 0) {
        return [];
    }
    const itemStep = 10 * 60 * 1000;
    const nullObj = sensorData?.[0]?.d.map(() => null) || [];
    const sensorDataFirstDate = new Date(sensorData?.[0]?.t);
    const sensorDataLastDate = new Date(sensorData[sensorData.length - 1].t);
    const startArr = [];
    const finishArr = [];

    let globalToMillis;

    let globalFromMillis;

    if (from) {
        globalFromMillis = moment(from, 'YYYY-MM-DDTHH:mm')
            .utc(false).valueOf();
    } else {
        globalFromMillis = moment(leaseStartTimestamp, 'DD.MM.YYYY HH:mm Z')
            .utc(false).valueOf();
    }

    if (to) {
        globalToMillis = moment(to, 'YYYY-MM-DDTHH:mm').utc(false).valueOf();
    } else if (shipmentEnd) {
        globalToMillis = moment(shipmentEnd, 'DD.MM.YYYY HH:mm:ss ZZ').utc(true).valueOf();
    } else if (leaseEndTimestamp) {
        globalToMillis = moment(leaseEndTimestamp, 'DD.MM.YYYY HH:mm ZZ').utc(false).valueOf();
    } else {
        globalToMillis = moment(baseLeaseUntil, dateTimeMaskTimestamp).utc(false).valueOf();
    }

    if (globalFromMillis + itemStep < sensorDataFirstDate.getTime()) {
        startArr.push({
            t: new Date(globalFromMillis - 60 * 60 * 1000).toISOString(),
            d: nullObj,
        });
    }

    if (sensorDataLastDate.getTime() + itemStep < globalToMillis) {
        finishArr.push({
            t: moment(globalToMillis + new Date().getTimezoneOffset() * 60 * 1000).utc(false).format(dateTimeISODate),
            d: nullObj,
        });
    }

    return [
        ...startArr,
        ...sensorData,
        ...finishArr,
    ];
};

export const momentAddOffset = (date: moment.Moment) => {
    return date.subtract({ minute: new Date().getTimezoneOffset() });
};

export const utcDateToEpoch = (date, mask = 'YYYY-MM-DDTHH:mm') => moment(date, mask)
    .utc(true)
    .subtract({ minute: new Date().getTimezoneOffset() })
    .valueOf();

export const CACHE_TIME = 5 * 60 * 1000;
