import { useCallback, useState } from 'react';

const useTabs = (defaultTab: number = 0) => {
    const [
        tab,
        setTab,
    ] = useState(defaultTab);

    const handleToNextTab: any = useCallback(() => {
        setTab(prev => prev + 1);
    }, [
        tab,
        setTab,
    ]);
    const handleToPrevTab: any = useCallback(() => {
        setTab(prev => prev - 1);
    }, [
        tab,
        setTab,
    ]);

    const resetTab: any = useCallback(() => {
        setTab(() => 1);
    }, [tab, defaultTab]);

    return [
        tab,
        handleToNextTab,
        handleToPrevTab,
        resetTab,
    ];
};

export default useTabs;
