function getEnviroment() {
    const url = window.location;

    if (url.host.includes('test')) {
        return ' (TEST)';
    }
    if (url.host.includes('dev')) {
        return ' (DEV)';
    }
    return null;
}

export default getEnviroment;
