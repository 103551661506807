import React, { useMemo, useState } from 'react';
import { ReactNodeLike } from 'prop-types';
import HeaderNavigationContext from 'app/context/HeaderNavigationContext';

type Props = {
    children: ReactNodeLike
}

const HeaderNavigationProvider = ({
    children,
}: Props) => {
    const [navigationCallbacks, setNavigationCallbacks] = useState<any>({
        onSkip: null,
    });

    const setNavigationCallback = useMemo(() => (navigation, callback) => {
        setNavigationCallbacks(prev => ({
            ...prev,
            [navigation]: callback,
        }));
    }, []);

    return (
        <HeaderNavigationContext.Provider
            value={{
                ...navigationCallbacks,
                setOnSkip: setNavigationCallback.bind(this, 'onSkip'),
            }}
        >
            {children}
        </HeaderNavigationContext.Provider>
    );
};

export default HeaderNavigationProvider;
