import { useQuery } from 'react-query';

import { CACHE_TIME } from 'app/utils/tools';
import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

function useFindMatchedEvidence({
    assetNumber,
}) {
    const {
        Get: getMatchingEvidenceRequest,
    } = useSecureBackendEndpoints('skyship/matching-evidence').requests;

    const getMatchingEvidence = async () => {
        const result = await getMatchingEvidenceRequest('', {
            assetNumber,
        });

        return result?.data || [];
    };

    return useQuery({
        queryKey: [
            'findMatchedEvidence',
        ],
        cacheTime: CACHE_TIME,
        staleTime: CACHE_TIME,
        queryFn: getMatchingEvidence,
        enabled: Boolean(assetNumber),
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });
}

export default useFindMatchedEvidence;
