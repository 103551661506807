import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        tabTitles: {
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px 0 10px 0',
        },
        title: {
            textAlign: 'center',
            margin: '0 25px 0 25px',
            fontStyle: 'Roboto, sans-serif',
            fontWeight: 200,
            color: '#000000DE',
        },
        mainTitle: {
            fontSize: 32,
        },
        subTitle: {
            fontSize: 24,
        },
    };
});

export default useStyles;
