import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    chartWrapper: {
        marginTop: 15,
    },
    toolbarIcon: {
        width: '16px!important',
        height: '14px!important',
    },
    mobileToolbar: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 10,
    },
    divider: {
        flex: '100%',
    },
    toolButton: {
        background: theme.palette.primary[200],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        outline: 'none',
        borderRadius: 3,
        marginRight: 5,
        minWidth: 34,
        maxWidth: 34,
        height: 25,
        '&:last-child': {
            margin: '0',
        },
        boxShadow: '0px 1px 3px #00000033',
    },
}));
