import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            boxSizing: 'border-box',
            padding: '0px 20px 0px 20px',
            height: '100%',
            width: '100%',
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            font: '36px Roboto, sans-serif',
            fontWeight: 200,
            textAlign: 'center',
            margin: 0,
        },
        buttonsRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        numberTitle: {
            font: '42px Roboto, sans-serif',
            fontWeight: 200,
            textAlign: 'center',
            wordBreak: 'break-word',
            margin: 0,
            width: '100%',

        },
        img: {
            height: 320,
            width: 320,
            [theme.breakpoints.down('xs')]: {
                width: 200,
                height: 200,
            },
        },
        btn: {
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 120,
            height: 36,
            font: '14px Roboto, sans-serif',
            fontWeight: 300,
            backgroundColor: theme.palette.primary[200],
            letterSpacing: '2.49px',
            color: theme.palette.common.white,
            textAlign: 'center',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0px 1px 3px $shadow_black',
            textTransform: 'uppercase',
            margin: 15,
        },
    };
});

export default useStyles;
