import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AcceptButton from 'app/components/AcceptButton';

import useStyles from './ComparePhotos.style';
import { PictureStepUserInput } from '../../utils/dataTypes';
import useContactInfo from '../../hooks/useContactInfo';

type Props = {
    currentImage: string,
    onSubmit: (...arg: any) => any,
    imageData?: PictureStepUserInput,
    label: string,
}

const ComparePhotos = ({
    label,
    currentImage,
    imageData,
    onSubmit,
}: Props) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const {
        data: contactData,
    }:any = useContactInfo(imageData.createdBy);

    const handleSubmit = useCallback(() => {
        onSubmit(true);
    }, [onSubmit]);

    const handleCancel = useCallback(() => {
        onSubmit(false);
    }, [onSubmit]);

    return (
        <>
            <div
                className={classes.images}
            >
                <div className={classes.imageContent}>
                    <span className={classes.imageTitle}>
                        {t('SENDER')}
                    </span>

                    <img
                        className={classes.image}
                        src={imageData?.attachmentUrl}
                        alt={label}
                    />
                    {
                        contactData && (
                            <span className={classes.imageDetails}>
                                {imageData.changedOn}
                                {' '}
                                |
                                {' '}
                                {`${contactData?.fullName?.firstName} ${contactData?.fullName?.lastName}`}
                            </span>
                        )
                    }

                </div>
                <div
                    className={classes.imageContent}
                >
                    <span className={classes.imageTitle}>
                        {t('RECEIVER')}
                    </span>

                    <img
                        className={classes.image}
                        src={currentImage}
                        alt={label}
                    />
                </div>
            </div>

            <AcceptButton
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitBtnText={t('MATCH')}
                cancelBtnText={t('DONT_MATCH')}
            />
        </>
    );
};

export default ComparePhotos;
