import { useContext, useMemo } from 'react';
import { AuthContext } from 'app/context/AuthContext';

const useUserRoles = () => {
    const { userInfo } = useContext(AuthContext);

    return useMemo(() => {
        return (userInfo && userInfo.assignedRoles) || [];
    }, [userInfo]);
};

export default useUserRoles;
