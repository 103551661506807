import { useCallback, useState } from 'react';

const CONTAINER_SERIAL_NUMBER = 'CONTAINER_SERIAL_NUMBER';
const CONTAINER_SERIAL_NUMBER_COMPARISON = 'CONTAINER_SERIAL_NUMBER_COMPARISON';
const PALLET_CODE = 'PALLET_CODE';
const PALLET_CODE_COMPARISON = 'PALLET_CODE_COMPARISON';
const PICTURE = 'PICTURE';
const PICTURE_COMPARISON = 'PICTURE_COMPARISON';

const generateUserInput = (stepType, input) => {
    switch (stepType) {
    case CONTAINER_SERIAL_NUMBER:
        return {
            serialNumber: input.target.value,
        };
    case CONTAINER_SERIAL_NUMBER_COMPARISON:
        return {
            serialNumber: input.target.value,
            comparisonMatching: true,
        };
    case PALLET_CODE:
        return {
            palletCode: input.target.value,
        };
    case PALLET_CODE_COMPARISON:
        return {
            palletCode: input.target.value,
            comparisonMatching: true,
        };
    case PICTURE:
        return {
            picture: {
                id: input.target.value.attachment,
            },
        };
    case PICTURE_COMPARISON:
        return {
            picture: {
                id: input.target.value.attachment,
            },
            comparisonMatching: input.target.value.comparisonMatching,
        };
    default:
        return input.target.value;
    }
};

const useStepsWithUserInput = (defaultSteps = []) => {
    const [
        steps,
        setSteps,
    ] = useState(defaultSteps);

    const addUserInputToSteps = useCallback((input, stepName) => {
        const stepsWithInput = steps.map(({
            id, choices, ...rest
        }) => {
            if (rest.stepName === stepName) {
                const userInput = generateUserInput(rest.stepType, input);

                global.console.log({
                    id, choices,
                });
                return {
                    ...rest,
                    isAnswered: true,
                    userInput,
                };
            }

            return rest;
        });

        setSteps(stepsWithInput);
    }, [
        steps,
        setSteps,
    ]);

    return {
        steps,
        setSteps,
        addUserInputToSteps,
    };
};

export default useStepsWithUserInput;
