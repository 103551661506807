import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MainMenu from 'app/screens/MainMenu';
import Layout from 'app/components/Layout';
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage';

import Process from 'app/screens/Process';
import getEnviroment from 'app/utils/getEnviroment';
import ErrorScreen from '../components/ErrorScreen';

const Root = () => {
    const {
        path,
    } = useRouteMatch();

    useEffect(() => {
        const env = getEnviroment();

        if (env) {
            document.title = `SkyShip ${env}`;
        }
    }, []);

    return (
        <Layout>
            <Switch>
                <Route
                    exact
                    path="/no-access-rights"
                >
                    <ErrorScreen title="ERROR_FORBIDDEN" />
                </Route>

                <Route
                    exact
                    path={path}
                >
                    <MainMenu />
                </Route>

                <Route
                    path={`${path}:process`}
                >
                    <Process />
                </Route>

                <Route
                    path={`${path}notfound/:id`}
                >
                    <ContainerValidationErrorMessage />
                </Route>
            </Switch>
        </Layout>
    );
};

export default Root;
