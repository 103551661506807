import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiReader from 'app/components/MultiReader';
import { useHistory } from 'react-router-dom';
import { Input } from '@material-ui/core';
import { DevPlug } from '../../../utils/DevComponents';
import styles from '../SerialNumber/SerialNumber.module.scss';
import FieldTitle from '../../FieldTitle';
import Button from '../../Button';

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const RawScan = ({
    title = 'SCAN_CONTAINER_CODE_RAW',
    subTitle = '',
    label,
    skippable = false,
    onChange,
    onSubmit,
}: Props) => {
    const {
        t,
    } = useTranslation();

    const [scannedCode, setScannedCode] = useState(null);
    const [manualInput, setManualInput] = useState('');
    const [showManualInput, setShowManualInput] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!scannedCode) return;

        onChange({
            target: {
                value: {
                    serialNumber: scannedCode,
                },
            },
        }, 'CHECK_TEMPERATURE');
        onSubmit();
    }, [scannedCode]);

    return (
        <>
            <FieldTitle
                title={t(showManualInput ? 'ENTER_CONTAINER_CODE_TITLE' : title)}
                subTitle={subTitle}
            />

            <DevPlug
                devFunction={setScannedCode}
                devFunctionName="setSerial"
            />

            <div
                className={[
                    styles.tabContent,
                    showManualInput ? styles.fullHeight : '',
                ].join(' ')}
            >
                {
                    !showManualInput && (
                        <>
                            <MultiReader
                                onScan={setScannedCode}
                                label={label}
                                skippable={skippable}
                                onSkip={onSubmit}
                            />
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('ENTER_MANUALLY')}
                                    onClick={() => {
                                        setShowManualInput(true);
                                    }}
                                />
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                />
                            </div>
                        </>
                    )
                }

                {
                    showManualInput && (
                        <div className={styles.manualInput}>
                            <Input
                                onKeyPress={(event => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setShowManualInput(false);
                                        setScannedCode(manualInput);
                                    }
                                })}
                                className={styles.input}
                                placeholder={t('ENTER_CODE')}
                                value={manualInput}
                                onChange={e => setManualInput(e.target.value)}
                            />
                            <div className={styles.divider} />
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    text={t('CANCEL')}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        setManualInput('');
                                    }}
                                />
                                <Button
                                    text={t('CONFIRM')}
                                    disabled={!manualInput}
                                    onClick={() => {
                                        setShowManualInput(false);
                                        setScannedCode(manualInput);
                                    }}
                                />
                            </div>

                        </div>
                    )
                }
            </div>

        </>
    );
};

export default RawScan;
