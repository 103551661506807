import React, { useMemo, useState } from 'react';
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useStyles from '../TemperatureInfo.style';
import getGlobalAssetUrl from '../../../../utils/getGlobalAssetUrl';
import {
    dateTimeMaskUTC,
    ICONS,
    TEMP_RANGE_CHECK,
    TEMPERATURE_RANGES,
    TemperatureIcons,
} from '../../../../utils/constants';

type Props = {
    temperatureRange?: string,
    tempRangeCheck?: TEMP_RANGE_CHECK,
    lastMeasuredTempInternal?: number,
    lastMeasuredTempInternalTimestamp?: number,
}

const rangeCheckIcons = {
    [TEMP_RANGE_CHECK.OK]: ICONS.tickmark_blue,
    [TEMP_RANGE_CHECK.NO_DATA]: ICONS.dots_round_grey,
    [TEMP_RANGE_CHECK.FAILED_TEMPERATURE]: ICONS.cross,
};

const MeasurementCard = ({
    temperatureRange = 'CRT',
    tempRangeCheck = TEMP_RANGE_CHECK.NO_DATA,
    lastMeasuredTempInternal = null,
    lastMeasuredTempInternalTimestamp = null,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [minutesDiff, setMinutesDiff] = useState<number>(null);

    const lastMeasuredAgo = useMemo(() => {
        if (!lastMeasuredTempInternalTimestamp) return t('TEMPERATURE.NOT_MEASURED_YET');

        const duration = moment
            .duration(moment().utc(false)
                .diff(moment(lastMeasuredTempInternalTimestamp, dateTimeMaskUTC).utc(true)));
        const minutes = Math.ceil(Math.abs(duration.asMinutes()));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        setMinutesDiff(minutes);

        const daysS = days !== 1 ? 'TEMPERATURE.DAYS' : 'TEMPERATURE.DAY';
        const hoursS = hours % 24 !== 1 ? 'TEMPERATURE.HOURS' : 'TEMPERATURE.HOUR';
        const minutesS = minutes % 60 !== 1 ? 'TEMPERATURE.MINUTES' : 'TEMPERATURE.MINUTE';

        return (days ? `${days} ${t(daysS)} ` : '')
            + (hours ? `${hours % 24} ${t(hoursS)} ` : '')
            + (minutes ? `${minutes % 60} ${t(minutesS)} ` : '')
            + t('TEMPERATURE.AGO');
    }, [lastMeasuredTempInternalTimestamp]);

    const lastMeasuredAgoColor = useMemo(() => {
        if (!minutesDiff || minutesDiff >= 24 * 60) return classes.red;
        if (minutesDiff >= 60 && minutesDiff < 24 * 60) return classes.orange;
        if (minutesDiff < 60) return classes.green;
        return '';
    }, [minutesDiff]);

    const lastMeasuredAgoText = useMemo(() => {
        return lastMeasuredTempInternal
            ? <span className={classes.fontSize96}>{`${lastMeasuredTempInternal}°C`}</span>
            : <span className={classes.fontSize36}>{t('NOT_ASSIGNED')}</span>;
    }, [lastMeasuredTempInternalTimestamp]);

    return (
        <>
            <div className={classes.cardHolder}>
                <div className={classes.temperatureTextWrapper}>
                    <span className={classes.temperatureTitle}>{t('TEMPERATURE.INTERNAL_TEMPERATURE')}</span>
                    <span className={classes.temperature}>
                        {lastMeasuredAgoText}
                    </span>

                </div>

                <div className={classes.measurementCardHolder}>
                    <Card>
                        <div className={classes.measurementCard}>
                            <div>
                                <span>{t('TEMPERATURE.MEASURED')}</span>
                                <span
                                    className={lastMeasuredAgoColor}
                                >
                                    {lastMeasuredAgo}
                                </span>
                            </div>
                            <div>
                                <span>{t('TEMPERATURE.ALLOWED_RANGE')}</span>
                                <span>
                                    <img
                                        className={classes.icon}
                                        src={getGlobalAssetUrl(TemperatureIcons[temperatureRange])}
                                        alt="tempIcon"
                                    />
                                    {TEMPERATURE_RANGES[temperatureRange]}
                                </span>
                            </div>

                            <div>
                                <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
                                <span>
                                    <img
                                        className={classes.icon}
                                        src={getGlobalAssetUrl(rangeCheckIcons[tempRangeCheck])}
                                        alt="check"
                                    />
                                    {tempRangeCheck === TEMP_RANGE_CHECK.NO_DATA
                                        ? t(`RANGE_CHECK_${tempRangeCheck}`) : ''}
                                </span>
                            </div>

                        </div>
                    </Card>
                </div>
            </div>

        </>
    );
};

export default MeasurementCard;
