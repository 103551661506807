import React from 'react';

import Header from 'app/components/Header';

import { ReactNodeLike } from 'prop-types';
import DrawBarProvider from '../../DrawBarProvider';
import styles from './Layout.module.scss';

type Props = {
    children: ReactNodeLike,
}

const Layout = ({
    children = '',
}: Props) => {
    return (
        <DrawBarProvider>
            <div className={styles.container}>

                <Header />

                <div className={styles.content}>
                    {children}
                </div>

            </div>

        </DrawBarProvider>
    );
};

export default Layout;
