import React from 'react';
import cx from 'classnames';
import CheckIcon from '@material-ui/icons/Check';

import useStyles from './ProgressBar.style';

type Props = {
    items: { progressGroup: number }[],
    progressCount: number,
}

const ProgressBar = ({
    items = [],
    progressCount,
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.progress}>
            <div
                className={classes.container}
            >
                {
                    items.map(({
                        progressGroup,
                    }, index) => {
                        const checkClassName = cx(
                            classes.progressCircle,
                            index < progressCount && classes.progressCircleCheck,
                            index === progressCount - 1 && classes.progressCircleCurrent,
                        );

                        return (
                            <div
                                className={checkClassName}
                                key={progressGroup}
                            >
                                {
                                    (index < progressCount - 1) && (
                                        <CheckIcon
                                            className={classes.progressCheck}
                                        />
                                    )
                                }
                            </div>
                        );
                    })
                }
            </div>
            <div
                className={classes.baseline}
            />
        </div>
    );
};

export default ProgressBar;
