import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
        },

        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25,
            height: '100%',
        },

        title: {
            font: '36px Roboto, sans-serif',
            fontWeight: 200,
        },

        icon: {
            color: theme.palette.primary[200],
            height: '70px !important',
            width: '70px !important',
        },

        btn: {
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50,
            width: 120,
            height: 36,
            font: '14px Roboto, sans-serif',
            fontWeight: 300,
            backgroundColor: theme.palette.primary[200],
            letterSpacing: '2.49px',
            color: theme.palette.common.white,
            textAlign: 'center',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0px 1px 3px #00000033',
        },
    };
});

export default useStyles;
