import React from 'react';

interface HeaderNavigationData {
    onSkip:any,
    setOnSkip:any

}
const defaultData = {
    onSkip: null,
    setOnSkip: null,
};
const HeaderNavigationContext = React.createContext<HeaderNavigationData>(defaultData);

export default HeaderNavigationContext;
