import React from 'react';

interface AppDrawerData {
    open: boolean,
    setOpen: any,
}

const defaultData = {
    open: false,
    setOpen: () => {
    },

};
const AppDrawerContext = React.createContext<AppDrawerData>(defaultData);

export default AppDrawerContext;
