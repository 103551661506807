/* eslint-disable prefer-destructuring */
const b64DataUrlToBlob = (dataUrl, contentType, sliceSize = 512) => {
    const b64Data = dataUrl.split(',')[1];

    const byteCharacters = window.atob(b64Data);

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {
        type: contentType,
    });
};

export default b64DataUrlToBlob;
