import React, { useCallback, useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import { dateToStr } from '@skycell-ag/shared-components';

import Button from 'app/components/Button';

import useStyles from './Confirmation.style';
import { dateTimeISO } from '../../../utils/constants';

type Props = {
    title: string,
    label: string,
    value?: {
        confirmed: boolean,
        confirmationOnZoned: string,
    },
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    checkIcon?: boolean,
}

const Confirmation = ({
    title,
    label,
    value = null,
    onChange,
    onSubmit,
    checkIcon = false,
}: Props) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const [
        confirmationTimestamp,
        setConfirmationTimestamp,
    ] = useState(null);

    const handleSubmit = useCallback(() => {
        onChange({
            target: {
                value: {
                    confirmed: true,
                    confirmationOnZoned: confirmationTimestamp,
                },
            },
        }, label);
    }, [
        onChange,
        label,
        confirmationTimestamp,
    ]);

    useEffect(() => {
        if (!confirmationTimestamp) {
            // @ts-ignore
            setConfirmationTimestamp(dateToStr(new Date(), dateTimeISO));
        }
        if (value) {
            onSubmit();
        }
    }, [
        value,
        onSubmit,
        confirmationTimestamp,
        setConfirmationTimestamp,
    ]);

    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>

                    {
                        checkIcon && (
                            <CheckIcon
                                className={classes.checkIcon}
                            />
                        )
                    }

                </div>

                <Button
                    text={t('NEXT')}
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default Confirmation;
