import React from 'react';
import { Loading } from '@skycell-ag/shared-components';
import { useStyles } from './LoadingSkyCell.style';

const LoadingSkyCell = () => {
    const classes = useStyles();

    return <Loading className={classes.loading} />;
};

export default LoadingSkyCell;
