import AppDrawerContext from 'app/context/AppDrawerContext';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReactNodeLike } from 'prop-types';
import { SwipeableDrawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUserRoles from 'app/hooks/useUserRoles';
import AppDrawerMenuItem from '../components/AppDrawerMenuItem';
import useStyles from '../components/Header/Header.style';
import { AppMenuItem, AppMenuItemType, Process } from '../utils/dataTypes';
import getGlobalAssetUrl from '../utils/getGlobalAssetUrl';
import useFetchProcesses from '../hooks/useFetchProcesses';
import { SSI_PROCESS_EXECUTION } from '../screens/MainMenu/MainMenu';
import { AuthContext } from '../context/AuthContext';
import useSecureBackendEndpoints from '../hooks/useSecureBackendEndpoints';

type Props = {
    children: ReactNodeLike
}

const processIconMap = (process) => ({
    CHECK_TEMPERATURE: 'icons/magnify.svg',
    RECEIVING: 'icons/checkboard.svg',
    SENDING: 'icons/truck_loading.svg',
})[process];

const convertProcessToMenuItem: any = (process: Process, t) => {
    return {
        label: t(`${process.processLabel}`),
        type: AppMenuItemType.ROUTE,
        route: `/${process.process}`,
        icon: processIconMap(process.process),
    };
};

const DrawBarProvider = ({ children = [] }: Props) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const { userInfo, logout } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useState<any>(null);
    const {
        Get: getUserDetails,
    } = useSecureBackendEndpoints('user/email').requests;

    const roles = useUserRoles();

    const hasRoles = useMemo(() => {
        return roles.includes(SSI_PROCESS_EXECUTION);
    }, [roles]);

    const {
        processes,
    } = useFetchProcesses(hasRoles);

    useEffect(() => {
        if (userInfo.email && !userDetails) {
            (async () => {
                const result = await getUserDetails(userInfo.email);

                setUserDetails(result?.data || null);
            })();
        }
    }, [userInfo.email]);

    const homeMenuItem = useMemo<AppMenuItem>(() => ({
        label: t('HOME'),
        type: AppMenuItemType.ROUTE,
        route: '/',
        icon: 'icons/home.svg',
    }), [i18n.language]);

    const processItems = useMemo<AppMenuItem>(() => {
        const children = processes?.map(process => convertProcessToMenuItem(process, t)) || [];

        if (children.length === 0) return null;
        return {
            label: t('PROCESSES'),
            type: AppMenuItemType.PARENT,
            children,
        };
    }, [i18n.language, processes]);

    const userMenuItems = useMemo<AppMenuItem[]>(() => [
        {
            label: t('ACCOUNT_SETTINGS'),
            type: AppMenuItemType.PARENT,
            children: [
                {
                    label: `${userInfo?.name}`,
                    type: AppMenuItemType.INFO,
                    icon: 'icons/person_white.svg',
                },
                {
                    label: userDetails?.companyName || t('NO_COMPANY'),
                    type: AppMenuItemType.INFO,
                    icon: 'icons/warehouse_white.svg',
                },
                {
                    label: t('LOGOUT'),
                    onClick: logout,
                    type: AppMenuItemType.ROUTE,
                    icon: 'icons/logout.svg',
                },
            ],
        },
    ], [i18n.language, userDetails]);

    return (
        <AppDrawerContext.Provider
            value={{
                open,
                setOpen,
            }}
        >

            <SwipeableDrawer
                anchor="left"
                classes={{ paper: classes.paper }}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <div className={classes.closer}>
                    {/* eslint-disable-next-line */}
                    <img
                        className={classes.x}
                        src={getGlobalAssetUrl('icons/closer.svg')}
                        alt="x"
                        onClick={() => setOpen(false)}
                    />
                </div>
                <div className={classes.drawer}>
                    {[homeMenuItem, processItems, ...userMenuItems]
                        .filter(it => it)
                        .map(menuItem => (
                            <AppDrawerMenuItem
                                key={menuItem.label}
                                {...menuItem}
                                onClose={() => setOpen(false)}
                            />
                        ))}
                </div>
            </SwipeableDrawer>

            {children}
        </AppDrawerContext.Provider>
    );
};

export default DrawBarProvider;
