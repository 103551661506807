import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl';

import useStyles from './ContainerValidationErrorMessage.style';
import Button from '../Button';

type Props = {
    serialNumber?: string,
    title?: string,
    error?: {
        errorCode?: string | number,
        message?: string,
    },
    onRepeat?: any,
    cancel?: boolean,
}

const ContainerValidationErrorMessage = ({
    serialNumber = '',
    error = null,
    title = 'CONTAINER',
    onRepeat = null,
    cancel = true,
}: Props) => {
    const classes = useStyles();
    const {
        t,
    } = useTranslation();

    const history = useHistory();
    const NotFoundImage = getAssetBlobUrl('skyship/images/error_not_found.svg');

    const errorTitle = useMemo(() => {
        if (error && error.errorCode) {
            return t(error.message);
        }

        return t('NO_DATA_FOR_CONTAINER');
    }, [error]);

    return (
        <div
            className={classes.container}
        >
            <div
                className={classes.header}
            >
                <h2
                    className={classes.title}
                >
                    {t(title)}
                </h2>

                <h1
                    className={classes.numberTitle}
                >
                    {serialNumber}
                </h1>
            </div>

            <img
                className={classes.img}
                src={NotFoundImage}
                alt="shipment"
            />

            <h2
                className={classes.title}
            >
                {t(errorTitle)}
            </h2>
            <div
                className={classes.buttonsRow}
            >
                {
                    onRepeat && (
                        <Button
                            classNames={classes.btn}
                            onClick={onRepeat}
                            text={t('REPEAT')}
                        />
                    )

                }
                {
                    cancel && (
                        <Button
                            classNames={classes.btn}
                            onClick={() => history.push('/')}
                            text={t('CANCEL')}
                        />
                    )
                }

            </div>
        </div>
    );
};

export default ContainerValidationErrorMessage;
