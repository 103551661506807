import { useMutation } from 'react-query';
import b64DataUrlToBlob from 'app/utils/b64DataUrlToBlob';
import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

const getAttachment = ({
    file,
    fileName,
    description = '',
    request,
}:any) => {
    const imgFile = new FormData();
    const blob = b64DataUrlToBlob(file, 'image/png');

    imgFile.append('file', blob);
    imgFile.append('fileName', fileName);
    imgFile.append('description', description);

    return request(
        'POST',
        '',
        imgFile,
        {
            'Content-Type': 'multipart/form-data',
        },
    )
        .then((data) => {
            return data.data;
        });
};

export const useGetAttachment = () => {
    const {
        FlexibleRequest: saveAttachmentRequest,
    } = useSecureBackendEndpoints('skyship/attachment').requests;

    return ({
        file,
        fileName,
        description,
    }: any) => getAttachment({
        file, fileName, description, request: saveAttachmentRequest,
    });
};

function useSaveAttachment() {
    const getAttachmentRequest = useGetAttachment();

    // @ts-ignore
    return useMutation(({
        file,
        fileName,
        description,
    }:any) => {
        return getAttachmentRequest(
            {
                file,
                fileName,
                description,
            },
        );
    }, {
        retry: false,
        refetchOnWindowFocus: false,
    });
}

export default useSaveAttachment;
