import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        tabControl: {
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            boxSizing: 'border-box',
            paddingTop: 25,
            paddingBottom: 25,
        },

        btn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            backgroundColor: theme.palette.common.white,
            font: '24px Roboto, sans-serif',
            fontWeight: 200,
            width: 140,

            span: {
                width: '100%',
                textAlign: 'center',
            },
        },

        icon: {
            color: theme.palette.primary[200],
            height: '70px !important',
            width: '70px !important',
        },
    };
});

export default useStyles;
