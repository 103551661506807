import React from 'react';
import { Classes } from 'jss';
import { useTranslation } from 'react-i18next';
import { TempRangeStatus } from '../../../../../utils/dataTypes';
import getGlobalAssetUrl from '../../../../../utils/getGlobalAssetUrl';
import { ICONS } from '../../../../../utils/constants';

type Props = {
    temperatureStatus: TempRangeStatus,
    classes: Classes
}
const TempRangeStatusIcon = (string: TempRangeStatus) => getGlobalAssetUrl(({
    [TempRangeStatus.EXCURSION]: ICONS.cross,
    [TempRangeStatus.IN_RANGE]: ICONS.checked_blue,
    [TempRangeStatus.NOT_APPLICABLE]: ICONS.dots_round_grey,
    [TempRangeStatus.UNKNOWN]: ICONS.dots_round_grey,
    [TempRangeStatus.WAITING_FOR_DATA]: ICONS.dots_round_grey,
})[string] || ICONS.dots_round_grey);

const RangeCheckResult = ({
    temperatureStatus,
    classes,
}: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
            <span>
                <img
                    className={classes.icon}
                    src={TempRangeStatusIcon(temperatureStatus)}
                    alt="tempCheck"
                />
                {
                    t(temperatureStatus)
                }
            </span>
        </>
    );
};

export default RangeCheckResult;
