import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        btn: {
            minWidth: 90,
            minHeight: 36,
            backgroundColor: theme.palette.primary[200],
            fontFamily: 'Roboto Medium!important',
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: '2.49px',
            color: theme.palette.common.white,
            textAlign: 'center',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0px 1px 3px #00000033',
            margin: '30px 20px 30px 20px',
            transition: '200ms ease',
            '&[disabled]': {
                opacity: 0.5,
            },
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > $progress': {
                color: theme.palette.common.white,
            },

        },
        ghost: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary[200],
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: theme.palette.primary[200],
            '& > $progress': {
                color: theme.palette.primary.main,
            },
        },
        upperCase: {
            textTransform: 'uppercase',
        },
        progress: {
            display: 'flex',
            width: '16px!important',
            height: '16px!important',
            marginRight: 5,
            position: 'absolute',
        },
        loadingText: {
            filter: 'blur(0.5px)',
        },
        text: {
            display: 'flex',
            transition: '100ms ease',
            fontFamily: "'Roboto Medium'!important",

        },
    };
});

export default useStyles;
