import { useQuery } from 'react-query';

import useSecureBackendEndpoints from '../useSecureBackendEndpoints';

function useOrderFromSelectedAsset({
    assetNumber,
    ...rest
}) {
    const {
        FlexibleRequest: getLatestShipment,
    } = useSecureBackendEndpoints(
        'skyship',
    ).requests;

    const params = {
        serialNumber: assetNumber,
        process: rest.process?.toUpperCase() || 'RECEIVING',
    };

    const paramString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    return useQuery({
        queryKey: [
            'findMatchedEvidence',
            {
                assetNumber,
                ...rest,
            },
        ],
        retry: false,
        queryFn: () => getLatestShipment('GET', `/latest-shipment-of-packaging?${paramString}`)
            .then(it => it.data),
        enabled: Boolean(assetNumber),
        onError: (error) => {
            global.console.log('TError', error);
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}

export default useOrderFromSelectedAsset;
