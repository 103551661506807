import axios, { Method } from 'axios';
import useJWTToken from '../useJWTToken';

export type Endpoint = 'business-objects' | 'sensor-data-access' | 'keycloak' | 'temperature-simulation'
const {
    REACT_APP_SKYMIND_API: apiUrl,
    REACT_APP_SENSOR_DATA_ACCESS_URL: sensorDataUrl,
    REACT_APP_SECURE_BACKEND_API: secureApi = 'https://secure-backend.dev.skycell.ch',
} = process.env;

const getConfig = (token: string, addedHeaders: object = {}) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
            ...addedHeaders,
        },
    });
};

const getQueries = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((arr, key) => {
        return [
            ...arr,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ];
    }, []);

    return `?${queryArr.join('&')}`;
};

type AbstractServiceParams = {
    endpoint?: Endpoint,
    version?: string,
    addedHeaders?: object,
    objectName?: string,
    subObjectName?: string,
    mockSource?: boolean,
    method?: Method,
    url?: string,
    secureObject?: boolean
}

const useAbstractService = ({
    endpoint = 'business-objects',
    version = 'v1',
    addedHeaders = {},
    objectName,
    subObjectName,
    mockSource = false,
    method = 'GET',
    url,
    secureObject = false,
}: AbstractServiceParams = {}) => {
    const token = useJWTToken();

    const instance = axios.create({
        baseURL: `${apiUrl}/${endpoint}/${version}`,
    });
    const sensorDataInstance = axios.create({
        baseURL: `${sensorDataUrl}/${version}`,
    });
    const secureInstance = axios.create({
        baseURL: `${secureApi}`,
    });
    const mockInstance = axios.create({
        baseURL: `https://14d6ca64-1e7d-486d-b8c3-8df59dbf4069.mock.pstmn.io/${endpoint}/${version}`,
    });

    const addObjectRecord = async (params: object = { page: 1 }, queryObj: object = null) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.post(
                `${objectName.toLowerCase()}${getQueries(queryObj)}`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
            return error;
        }
    };

    const filterFlatObject = async (params: object = { page: 1 }, queryObj: object = null) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.post(
                `${objectName.toLowerCase()}/filter${getQueries(queryObj)}`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const filterRequestObject = async (params: object = { page: 1 }, queryObj: object = null) => {
        const config = getConfig(token, {
            ...addedHeaders,
            ...(secureObject ? { accept: 'application/vnd.skycell.flat+json' } : {}),
        });

        try {
            if (mockSource) {
                return await mockInstance.post(
                    `${objectName.toLowerCase()}/filter-request${getQueries(queryObj)}`,
                    params,
                    config,
                );
            }
            return await instance.post(
                `${objectName.toLowerCase()}/filter-request${getQueries(queryObj)}`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const filterRequestWithSubObject = async (params: object = { page: 1 }, queryObj: object = null) => {
        const config = getConfig(token, {
            ...addedHeaders,
            ...(secureObject ? { accept: 'application/vnd.skycell.flat+json' } : {}),
        });

        try {
            if (mockSource) {
                return await mockInstance.post(
                    `${objectName.toLowerCase()}/filter-request${
                        subObjectName ? `/${subObjectName}` : ''
                    }${getQueries(queryObj)}`,
                    params,
                    config,
                );
            }
            return await instance.post(
                `${objectName.toLowerCase()}/filter-request${
                    subObjectName ? `/${subObjectName}` : ''
                }${getQueries(queryObj)}`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const getById = async (id: string | number) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.get(`${objectName.toLowerCase()}/${id}`, config);
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const rawRequest = async (customUrl) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await secureInstance.get(`${customUrl || url}`, config);
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const getFieldCount = async (fieldName: string, params: object = { page: 1 }) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.post(
                `${objectName.toLowerCase()}/filter-request/count/${fieldName}`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const updateObject = async (data: object) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.put(objectName.toLowerCase(), data, config);
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const makeRequest = async (url: string, data = null) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance({
                method,
                url,
                data,
                ...config,
            });
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const makeExternalRequest = async (url: string, data = null, headers = {}) => {
        try {
            return await axios({
                method,
                url,
                data,
                headers,
            });
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const makeMockRequest = async (data = null) => {
        try {
            return await axios({
                method,
                url,
                data,
            });
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const getLoggerSensorData = async (params: object = {}) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await sensorDataInstance.post(
                'logger/custom',
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    const getPackagingSensorData = async (serialNumber: string, params: object = {}) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await sensorDataInstance.post(
                `virtual/container/${serialNumber}/custom`,
                params,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };
    const getOrderFromSelectedAsset = async (
        assetNumber: any,
        processName: string,
    ) => {
        const config = getConfig(token, addedHeaders);

        try {
            return await instance.get(
                `orderfromselectedasset?assetNumber=${assetNumber}&process=${processName}`,
                config,
            );
        } catch (error) {
            global.console.error(error);
        }
        return null;
    };

    return {
        addObjectRecord,
        filterRequestWithSubObject,
        filterRequestObject,
        filterFlatObject,
        getById,
        rawRequest,
        getFieldCount,
        makeExternalRequest,
        makeMockRequest,
        makeRequest,
        getLoggerSensorData,
        getPackagingSensorData,
        updateObject,
        getOrderFromSelectedAsset,
    };
};

export default useAbstractService;
