import React from 'react';

import { ReactNodeLike } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import useStyles from './Button.style';

type Props = {
    text?: string | ReactNodeLike,
    onClick?: (...arg: any) => any,
    classNames?: string,
    ghost?: boolean,
    loading?: boolean,
    upperCase?: boolean,
    disabled?:boolean,
}

const Button = ({
    text = '',
    classNames = '',
    upperCase = true,
    onClick = () => {},
    loading = false,
    ghost = false,
    disabled = false,
}: Props) => {
    const classes = useStyles();

    return (
        <button
            type="button"
            disabled={disabled || loading}
            className={[
                classes.btn,
                classNames,
                ghost ? classes.ghost : '',
                upperCase ? classes.upperCase : '',
            ].join(' ')}
            onClick={onClick}
        >
            {loading && (
                <CircularProgress
                    className={classes.progress}
                    size={25}
                />
            )}
            <span className={[classes.text, loading ? classes.loadingText : ''].join(' ')}>{text}</span>
        </button>
    );
};

export default Button;
