import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        header: {
            position: 'sticky',
            top: 0,
            zIndex: 99,
            width: '100%',
            minHeight: 56,
            backgroundColor: theme.palette.primary[200],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        iconHolder: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        headerIcon: {
            width: 48,
            height: 40,
        },
        btns: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        menu: {
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'transparent',
            width: 50,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'content-box',
        },
        icon: {
            padding: 5,
            height: 40,
            width: 40,
        },
        headerButton: {
            marginRight: 15,
            fontSize: 16,
            padding: '10px 17px',
            boxShadow: `0px 0px 5px 0px ${theme.palette.primary[500]}`,
            letterSpacing: '1px',
            textTransform: 'uppercase',
            backgroundColor: theme.palette.common.white,
            fontFamily: 'Roboto Medium',
            outline: 'none',
            border: '1px solid',
            borderColor: theme.palette.primary[500],
            color: theme.palette.primary[300],
            borderRadius: 5,
        },
        drawerBack: {
            backgroundColor: theme.palette.primary[100],
        },
        paper: {
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar-track': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
                borderRadius: '10px',
                backgroundColor: theme.palette.primary[50],
                width: '7px',

            },
            '&::-webkit-scrollbar': {
                width: '7px',
                backgroundColor: theme.palette.primary[200],
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                width: '7px',
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
                backgroundColor: theme.palette.primary[400],
            },
            backgroundColor: `${theme.palette.primary[100]}!important`,
            width: 'auto',
        },
        drawer: {
            minWidth: '100px',
            width: 'min-content',
        },
        closer: {
            backgroundColor: theme.palette.primary[200],
            height: 56,
            minHeight: 56,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 15,
            borderBottom: '1px white solid',

        },
        x: {
            height: 32,
        },
    };
});

export default useStyles;
