import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const useJWTToken = () => {
    const { token } = useContext(AuthContext);

    return token;
};

export default useJWTToken;
