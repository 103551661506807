import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            boxSizing: 'border-box',
            padding: '0px 20px 0px 20px',
            height: '100%',
            width: '100%',
            font: 'normal normal 300 36px/43px Roboto',
            textAlign: 'center',
            color: '#000000DE',

        },
        img: {
            width: 360,
        },
    };
});

export default useStyles;
