import React, { useCallback, useMemo, useRef } from 'react';
import Webcam from 'react-webcam';

import useEnumerateDevices from 'app/hooks/useEnumerateDevices';
import AcceptButton from 'app/components/AcceptButton';
import SkipButton from 'app/components/SkipButton';
import isMediaDevicesAvailable from 'app/utils/isMediaDevicesAvailable';

import PhotoPickerFileInput from './PhotoPickerFileInput';
import useStyles from './PhotoPicker.style';
import { Constraints } from '../../utils/dataTypes';

type Props = {
    value?: string,
    label?: string,
    onChange: (...arg:any) => any,
    onSubmit: (...arg:any) => any,
    onSkip: (...arg:any) => any,
    skippable?: boolean,
}

const CAMERA_WIDTH = 320;
const CAMERA_HEIGHT = 320;

const PhotoPicker = ({
    label = '',
    value = '',
    onChange,
    onSubmit,
    onSkip,
}: Props) => {
    const classes = useStyles();
    const input = useRef(null);

    const device = useEnumerateDevices();

    const getPhotoFromInput = useCallback((event) => {
        onChange({
            target: {
                value: window.URL.createObjectURL(event.target.files[0]),
            },
        }, label);
    }, [
        onChange,
        label,
    ]);

    const handleCancel = useCallback(() => {
        onChange({
            target: {
                value: null,
            },
        }, label);
    }, [
        onChange,
        label,
    ]);

    const captureImage = useCallback(() => {
        const imageSrc: Constraints = input.current.getScreenshot({
            width: 720,
            height: 720,
            aspectRatio: 1,
        });

        onChange({
            target: {
                value: imageSrc,
            },
        }, label);
    }, [
        input,
        label,
        onChange,
    ]);

    const videoConstraints = useMemo(() => {
        const constraints: Constraints = {
            audio: false,
            height: 720,
            width: 720,
            video: {
                aspectRatio: 1,
            },
        };

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode;
        }

        return constraints;
    }, [device]);

    if (!isMediaDevicesAvailable() && device) {
        return (
            <PhotoPickerFileInput
                value={value}
                onChange={getPhotoFromInput}
            />
        );
    }

    if (value) {
        return (
            <>
                <div className={classes.imageContainer}>
                    <img
                        className={classes.image}
                        src={value}
                        alt={label}
                    />
                </div>

                <div
                    className={classes.cameraControls}
                >
                    <AcceptButton
                        onCancel={handleCancel}
                        onSubmit={onSubmit}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <Webcam
                audio={false}
                height={CAMERA_HEIGHT}
                width={CAMERA_WIDTH}
                className={classes.capturer}
                ref={input}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                screenshotQuality={1}
            />

            <div
                className={classes.cameraControls}
            >
                <button // eslint-disable-line jsx-a11y/control-has-associated-label
                    type="button"
                    className={classes.captureButton}
                    onClick={captureImage}
                />

                {
                    onSkip && (
                        <SkipButton
                            onClick={onSkip}
                        />
                    )
                }
            </div>
        </>
    );
};

export default PhotoPicker;
