import moment from 'moment';

const dateToISO = (date): string => {
    if (!date) return null;

    let dateStr: string;

    switch (typeof date) {
    case 'number':
    case 'object':
        dateStr = moment(date).format('YYYY-MM-DDTHH:mm');
        break;
    default:
        dateStr = date;
        break;
    }
    const regCorrectDate = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/g;

    if (dateStr.match(regCorrectDate)) {
        return dateStr;
    }

    const regDateWithoutTime = /\d{4}-\d{2}-\d{2}$/g;
    const currentTime = new Date();

    const dateArr = dateStr.match(regDateWithoutTime)
        ? `${dateStr}T${currentTime.getUTCHours().toString().padStart(2, '0')}:\
        ${currentTime.getUTCMinutes().toString().padStart(2, '0')}:00`.split(':')
        : dateStr.split(':');

    dateArr.pop();

    return dateArr.join(':');
};

export default dateToISO;
