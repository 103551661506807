import { Drawer } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

type Props = {
    installEvent: any
}
const InstallPrompt = ({
    installEvent,
}: Props) => {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={() => setOpen(false)}
        >
            <div style={{
                display: 'flex',
                height: '60px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <span>{t('INSTALL_AS_APP_PROMPT')}</span>
                <Button
                    text={t('INSTALL')}
                    onClick={() => {
                        installEvent.prompt();
                        setOpen(false);
                    }}
                />
            </div>
        </Drawer>
    );
};

export default InstallPrompt;
