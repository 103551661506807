import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => {
    return {
        images: {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },

        imageContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 20,
        },

        imageTitle: {
            font: '24px Roboto Light',
            marginBottom: 5,
        },
        imageDetails: {
            font: 'normal normal 300 12px/14px Roboto',
            color: '#000000DE',
            margin: '4px 2px',

        },
        image: {
            width: 320,
            height: 320,
            boxSizing: 'border-box',
            overflow: 'hidden',
            objectFit: 'cover',
            objectPosition: 'center',
        },
    };
});

export default useStyles;
